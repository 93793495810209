import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import styled, { withTheme } from "styled-components";
import { getSpacing, breakpoints } from "theme";
// Icons
import IconCompass from "media/icons/compass.svg";
import IconCursor from "media/icons/cursor.svg";
import IconCoffee from "media/icons/coffee.svg";
import IconRocket from "media/icons/rocket.svg";
import IconKpi from "media/icons/kpi.svg";
// Components
import FeaturesItem from "./Item";

const FeaturesWrapper = styled.div`
  display: grid;
  grid-gap: ${getSpacing(22)};
  grid-template-columns: repeat(2, 1fr);
  padding: ${getSpacing(8)} 0;
  @media (max-width: ${breakpoints.tablet}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

function Features(props) {
  const { theme, locale } = props;
  const { items } = locale;
  const { color1, color2, color3, color4 } = theme.palettes;

  const icons = {
    discovery: {
      icon: IconCompass,
      colors: color1
    },
    uxui: {
      icon: IconCursor,
      colors: color2
    },
    development: {
      icon: IconCoffee,
      colors: color3
    },
    launch: {
      icon: IconRocket,
      colors: color4
    },
    kpi: {
      icon: IconKpi,
      colors: color4
    }
  };

  return (
    <FeaturesWrapper>
      {items.map(item => (
        <FeaturesItem
          key={item.code}
          title={item.title}
          {...get(icons, item.code, {})}
        >
          {item.description}
        </FeaturesItem>
      ))}
    </FeaturesWrapper>
  );
}

Features.propTypes = {
  // eslint-disable-next-line
  theme: PropTypes.object
};

export default withTheme(Features);
