import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Color from "color";
import { getSpacing } from "theme";
// Components
import { Subtitle2, Body2 } from "components/UI/Typography";

const CardLink = styled.div.attrs({ as: "a", target: "_blank" })`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palettes.bg.secondary};
  border-radius: ${({ theme }) => theme.radius.big};
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0px -10px 34px 12px ${({ color }) =>
      Color(color)
        .fade(0.9)
        .string()};
  padding: 0;
  margin: 0;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    box-shadow: 0px -15px 50px 12px ${({ color }) =>
          Color(color)
            .fade(0.65)
            .string()},
      0px 10px 20px 8px black;
    /* transform: scale(1.1); */
    transform: translateY(-4px);
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    width: 50%;
    fill: red;
  }
`;

const CardHeader = styled.div`
  position: relative;
  padding-top: 80%; /* hack to maintain ratio */
  background: ${({ color }) => color};
  background: ${({ theme }) => theme.palettes.bg.primary};
`;

const CardDescription = styled.div`
  padding: ${getSpacing(8)} ${getSpacing(6)};
  ${Subtitle2} {
    margin: 0;
    color: white;
  }
  ${Body2} {
    color: ${({ theme }) => theme.palettes.text.secondary};
  }
`;

function PortfolioItem(props) {
  const { icon, title, description, url, color, visible } = props;

  if (!visible) return null;

  return (
    <CardLink color={color} href={url}>
      <CardHeader color={color}>
        {icon && <LogoWrapper>{React.createElement(icon)}</LogoWrapper>}
      </CardHeader>
      <CardDescription color={color}>
        <Subtitle2 as="h4">{title}</Subtitle2>
        <Body2 as="p">{description}</Body2>
      </CardDescription>
    </CardLink>
  );
}

PortfolioItem.propTypes = {
  icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired
};

export default PortfolioItem;
