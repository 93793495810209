import React from "react";
// Components
import Layout from "components/Layout";
// Blocks
import Header from "components/Header";
import Intro from "components/Intro";
import Features from "components/Features";
import Portfolio from "components/Portfolio";
import Pricing from "components/Pricing";
import Footer from "components/Footer";

const IndexPage = ({ locales }) => {
  const {
    metatagsLocale,
    navbarLocale,
    headerLocale,
    introLocale,
    featuresLocale,
    portfolioLocale,
    pricingLocale,
    linksLocale
  } = locales;

  return (
    <Layout metatagsLocale={metatagsLocale.index} navbarLocale={navbarLocale}>
      <Header locale={headerLocale} links={linksLocale} />
      <Intro locale={introLocale} />
      <Features locale={featuresLocale} />
      <Pricing locale={pricingLocale} links={linksLocale} />
      <Portfolio locale={portfolioLocale} links={linksLocale} />
      <Footer />
    </Layout>
  );
};
export default IndexPage;
