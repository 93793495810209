import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { get } from "lodash";
import { getSpacing, breakpoints } from "theme";
// Helpers
import { trackGAEvent } from "helpers/ga";
// Components
import { Subtitle1 } from "components/UI/Typography";
import { ButtonLinkLine } from "components/UI/ButtonLink";
import PortfolioItem from "./Item";
// Logos
import codetalks from "media/images/projects/logos/codetalks.svg";
import fanunlock from "media/images/projects/logos/fanunlock.svg";
import gamelingu from "media/images/projects/logos/gamelingu.svg";
import twentyfourhdone from "media/images/projects/logos/twentyfourhdone.svg";
import memoshape from "media/images/projects/logos/memoshape.svg";
import musicalfy from "media/images/projects/logos/musicalfy.svg";

const logos = {
  codetalks,
  fanunlock,
  gamelingu,
  twentyfourhdone,
  memoshape,
  musicalfy
};

const Title = styled(Subtitle1).attrs({ as: "h3" })`
  display: block;
  text-align: center;
`;

const CTAWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${getSpacing(28)} 0;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${getSpacing(25)};
  grid-template-columns: repeat(3, 1fr);
  padding: 0;
  @media (max-width: ${breakpoints.desktop}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${breakpoints.tabletLandscape}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const PortfolioWrapper = styled.div`
  padding: ${getSpacing(8)} 0;
  ${Grid} {
    margin-top: ${getSpacing(38)};
  }
`;

function Portfolio({ locale, links }) {
  const { title, buttonLabel, items } = locale;
  const { typeformUrl } = links;

  return (
    <PortfolioWrapper>
      <Title>{title}</Title>
      <Grid>
        {items.map(item => (
          <PortfolioItem
            key={item.code}
            icon={get(logos, item.code, null)}
            {...item}
          />
        ))}
      </Grid>
      <CTAWrapper>
        <ButtonLinkLine
          onClick={() => trackGAEvent({ category: "Portfolio Button" })}
          href={typeformUrl}
        >
          {buttonLabel}
        </ButtonLinkLine>
      </CTAWrapper>
    </PortfolioWrapper>
  );
}

Portfolio.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
};

export default Portfolio;
