import React from "react";
import styled from "styled-components";
// Components
import LangSwitch from "./LangSwitch";
// Images
import Logo from "media/images/wakot.svg";

const NavbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    margin: 0;
    max-width: 100px;
  }
`;

const Navbar = ({ locale }) => {
  const { languages } = locale;
  return (
    <NavbarWrapper>
      <Logo alt="Wakot" />
      <LangSwitch languages={languages} />
    </NavbarWrapper>
  );
};

export default Navbar;
