import React from "react";
import styled from "styled-components";
import { getSpacing } from "theme";
// Components
import { Body1 } from "components/UI/Typography";

const IntroWrapper = styled.div`
  padding: ${getSpacing(15)} 0;
`;

function Intro({ locale }) {
  const items = locale.items;
  return (
    <IntroWrapper>
      {items.map((item, i) => (
        <Body1 key={i} as="p">
          {item}
        </Body1>
      ))}
    </IntroWrapper>
  );
}

export default Intro;
