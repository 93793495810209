import styled from "styled-components";
import { getSpacing } from "theme";

export const ButtonLink = styled.a.attrs({ target: "_blank" })`
  display: inline-block;
  padding: ${getSpacing(4)} ${getSpacing(6)};
  border-radius: ${({ theme }) => theme.radius.small};
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  z-index: 1;
`;

export const ButtonLinkLine = styled(ButtonLink)`
  color: ${({ theme }) => theme.palettes.text.primary};
  border: 2px solid ${({ theme }) => theme.palettes.text.primary};
  &:hover {
    color: ${({ theme }) => theme.palettes.accent.primary};
    border-color: ${({ theme }) => theme.palettes.accent.primary};
  }
`;

export const ButtonLinkLineAccent = styled(ButtonLink)`
  color: ${({ theme }) => theme.palettes.accent.primary};
  border: 2px solid ${({ theme }) => theme.palettes.accent.primary};
  &:hover {
    opacity: 0.8;
  }
`;
