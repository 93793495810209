import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import Color from "color";
import { getSpacing } from "theme";
// Animations
import animationData from "media/animations/rocket.json";

const HEIGHT = 500;

const Gradient = styled.div`
  background: radial-gradient(
    closest-side at 30% 50%,
    ${({ theme }) =>
        Color(theme.palettes.bg.primary)
          .fade(1)
          .string()}
      40%,
    ${({ theme }) => Color(theme.palettes.bg.primary).string()} 100%
  );
`;

const AnimationWrapper = styled.div`
  /* Fix to improve the position */
  position: relative;
  top: ${getSpacing(8)};
  display: flex;
  align-items: center;
  height: ${HEIGHT * 0.7}px;
  overflow: hidden;
  ${Gradient} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
`;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMinYMin meet"
  }
};

function Animation() {
  return (
    <AnimationWrapper>
      <Lottie height={HEIGHT} options={defaultOptions} speed={0.5} />
      <Gradient />
    </AnimationWrapper>
  );
}

export default Animation;
