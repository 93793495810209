import React from "react";
import styled from "styled-components";
import { Caption } from "components/UI/Typography";
import { getSpacing } from "theme";

const Text = styled(Caption).attrs({ as: "small" })`
  color: ${({ theme }) => theme.palettes.text.secondary};
  z-index: 1;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: ${getSpacing(12)} 0 0 0;
`;

function Footer() {
  return (
    <FooterWrapper>
      <Text>©{new Date().getFullYear()} Wakot</Text>
      <Text>
        info@wakot.com
        <br />
        Calle de Juan de Mariana, 15, 28045 Madrid
      </Text>
    </FooterWrapper>
  );
}

export default Footer;
