import React from "react";
import styled from "styled-components";
import { getSpacing, breakpoints } from "theme";
// Helpers
import { trackGAEvent } from "helpers/ga";
// Components
import { Title } from "components/UI/Typography";
import { ButtonLinkLine } from "components/UI/ButtonLink";
import Animation from "./Animation/";

const TitleStyled = styled(Title)`
  span {
    display: block;
  }
  .accent {
    color: ${({ theme }) => theme.palettes.accent.primary};
  }
  @media (max-width: ${breakpoints.mobileLandscape}px) {
    font-size: 2rem;
  }
`;

const BlockText = styled.div``;

const BlockAnimation = styled.div`
  padding-left: ${getSpacing(5)};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${getSpacing(8)} 0;
  ${BlockAnimation} {
    flex: 1;
    @media (max-width: ${breakpoints.tabletLandscape}px) {
      display: none;
    }
  }
  ${ButtonLinkLine} {
    margin-top: ${getSpacing(10)};
  }
`;

function Header({ locale, links }) {
  const { title1, title2 } = locale;
  const { typeformUrl } = links;
  return (
    <HeaderWrapper>
      <BlockText>
        <TitleStyled as="h1">
          <span>{title1}</span>
          <span className="accent">{title2}</span>
        </TitleStyled>
        <ButtonLinkLine
          onClick={() => trackGAEvent({ category: "Header Button" })}
          href={typeformUrl}
        >
          {locale.buttonLabel}
        </ButtonLinkLine>
      </BlockText>
      <BlockAnimation>
        <Animation />
      </BlockAnimation>
    </HeaderWrapper>
  );
}

export default Header;
