import { trackCustomEvent } from "gatsby-plugin-google-analytics";

export const trackGAEvent = options => {
  const { category, action = "click", label = null } = options;
  trackCustomEvent({
    category,
    action,
    label
  });
};
