import styled from "styled-components";

export const Title = styled.span`
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  word-spacing: 0.1rem;
  line-height: 3.5rem;
`;

export const Subtitle1 = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  word-spacing: 0.1rem;
`;

export const Subtitle2 = styled.span`
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
`;

export const Subtitle3 = styled.span`
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
`;

export const Body1 = styled.span`
  font-size: 1.2rem;
  line-height: 2.5rem;
`;

export const Body2 = styled.span`
  font-size: 1rem;
  line-height: 2rem;
`;

export const Caption = styled.span`
  font-size: 0.8rem;
  line-height: 1rem;
`;
