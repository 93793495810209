import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { getSpacing, breakpoints } from "theme";
// Helpers
import { trackGAEvent } from "helpers/ga";
// Components
import { Subtitle1, Body2 } from "components/UI/Typography";
import { ButtonLinkLineAccent } from "components/UI/ButtonLink";

const Title = styled(Subtitle1).attrs({ as: "h5" })`
  color: ${({ theme }) => theme.palettes.accent.primary};
  margin: 0;
`;

const Description = styled(Body2).attrs({ as: "p" })`
  color: ${({ theme }) => theme.palettes.text.secondary};
  margin: 0;
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  ${Description} {
    margin-top: ${getSpacing(4)};
  }
`;

const CTABlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoBoxWrapper = styled.div`
  @keyframes fadein {
    from {
      opacity: 0;
      transform: translatey(10px);
    }
    to {
      opacity: 1;
      transform: translatey(0px);
    }
  }
  animation: fadein 1s;
  display: flex;
  flex-direction: row;
  border-radius: ${({ theme }) => theme.radius.medium};
  background-color: ${({ theme }) => theme.palettes.bg.secondary};
  box-shadow: 0px 10px 22px -5px rgba(0, 0, 0, 0.3);
  padding: ${getSpacing(16)} ${getSpacing(12)};
  z-index: 2;
  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    ${CTABlock} {
      margin-top: ${getSpacing(10)};
    }
  }

  ${InfoBlock} {
    flex: 2;
  }
  ${CTABlock} {
    flex: 1;
  }
`;

function InfoBox(props) {
  const { title, buttonLabel, buttonUrl, description, id, ...rest } = props;
  return (
    <InfoBoxWrapper id="infoBox" {...rest}>
      <InfoBlock>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </InfoBlock>
      <CTABlock>
        <ButtonLinkLineAccent
          onClick={() =>
            trackGAEvent({ category: "Pricing Button", label: id })
          }
          href={buttonUrl}
        >
          {buttonLabel}
        </ButtonLinkLineAccent>
      </CTABlock>
    </InfoBoxWrapper>
  );
}

InfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default InfoBox;
