import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Color from "color";
import { getSpacing, breakpoints } from "theme";
// Components
import { Subtitle2, Body2 } from "components/UI/Typography";

const FeaturesItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.tablet}px) {
    align-items: center;
  }
  ${Subtitle2} {
    padding: ${getSpacing(3)} 0;
  }
  ${Body2} {
    color: ${({ theme }) => theme.palettes.text.secondary};
  }
`;

const IconCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: ${({ primary }) =>
    Color(primary)
      .fade(0.90)
      .string()};
  svg {
    fill: ${({ primary }) => primary};
    width: 40%;
  }
`;

function FeaturesItem(props) {
  const { title, colors, icon, children } = props;

  return (
    <FeaturesItemWrapper>
      <IconCircle {...colors}>{React.createElement(icon)}</IconCircle>
      <Subtitle2 as="h3">{title}</Subtitle2>
      <Body2 as="p">{children}</Body2>
    </FeaturesItemWrapper>
  );
}

FeaturesItem.propTypes = {
  title: PropTypes.string,
  colors: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string
  }),
  icon: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default FeaturesItem;
