import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { getSpacing, breakpoints } from "theme";
// Components
import { Subtitle2, Body2 } from "components/UI/Typography";

const Title = styled(Subtitle2).attrs({ as: "h4" })``;

const Feature = styled(Body2).attrs({ as: "p" })`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.palettes.text.secondary};
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: ${getSpacing(2)} 0;
  margin: 0;
  &:last-of-type {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
`;

const Price = styled(Subtitle2)``;

const Card = styled.div`
  @keyframes launch {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  @keyframes circle {
    from {
      transform: rotate(0deg) translateX(5px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(5px) rotate(-360deg);
    }
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.radius.medium};
  border: 3px solid ${({ theme }) => theme.palettes.bg.secondary};
  cursor: pointer;
  overflow: hidden;
  padding: ${getSpacing(8)} ${getSpacing(10)};
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${({ theme }) => theme.palettes.accent.primary};
      @media (min-width: ${breakpoints.tabletLandscape}px) {
        transform: translateY(-12px);
      }
      ${Title}, ${Price} {
        color: ${({ theme }) => theme.palettes.accent.primary};
      }
      svg {
        animation: ${({ animation }) => animation};
      }
    `}
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    height: auto;
    width: 30%;
  }
`;

const CardBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${getSpacing(4)} 0;
  ${Price} {
    margin-top: ${getSpacing(12)};
  }
`;

function PricingItem(props) {
  const {
    title,
    price,
    icon,
    animation,
    features,
    onSelectCard,
    isSelected
  } = props;

  return (
    <Card isSelected={isSelected} animation={animation} onClick={onSelectCard}>
      <CardHeader>
        {React.createElement(icon)}
        <Title>{title}</Title>
      </CardHeader>
      <CardBody>
        <div>
          {features.map(feature => (
            <Feature key={feature}>{feature}</Feature>
          ))}
        </div>
        <Price>{price}</Price>
      </CardBody>
    </Card>
  );
}

PricingItem.defaultProps = {
  isSelected: false
};

PricingItem.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  animation: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectCard: PropTypes.func.isRequired,
  isSelected: PropTypes.bool
};

export default PricingItem;
