import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { getSpacing } from "theme";
// Components
import { Caption } from "components/UI/Typography";

const Link = styled(Caption).attrs({ as: "a" })`
  opacity: 0.5;
  color: ${({ theme }) => theme.palettes.text.secondary};
  padding: ${getSpacing(0.5)} ${getSpacing(1.5)};
  font-weight: ${({ isSelected }) => (isSelected ? "bold" : "inherit")};
`;

const LangSwitchWrapper = styled.div`
  ${Link} {
    margin: 0 ${getSpacing(0.5)};
  }
`;

function LangSwitch(props) {
  const { languages } = props;
  return (
    <LangSwitchWrapper>
      {languages.map(language => (
        <Link
          key={language.label}
          isSelected={language.selected}
          href={!language.selected ? language.url : null}
        >
          {language.label}
        </Link>
      ))}
    </LangSwitchWrapper>
  );
}

LangSwitch.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
      selected: PropTypes.bool
    })
  )
};

export default LangSwitch;
