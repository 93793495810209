import React from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";
import ReactBreakpoints from "react-breakpoints";
import Color from "color";
import theme, { getSpacing, breakpoints } from "theme";
// Components
import SEO from "components/Seo";
import Navbar from "components/Navbar";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.palettes.bg.primary};
    color: ${({ theme }) => theme.palettes.text.primary};
    user-select: none;
  }
  a,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
  }
`;

const ScrollbarsWrapper = styled.div`
  overflow: auto;
  height: 100vh;
  width: 100vw;
`;

const LayoutWrapper = styled.div`
  padding: 32px 12px 100px 12px;
  padding: ${getSpacing(8)} ${getSpacing(3)};
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: ${breakpoints.desktopLarge}px) {
    padding: ${getSpacing(8)} ${getSpacing(12)};
  }
  @media (max-width: ${breakpoints.mobileLandscape}px) {
    padding: ${getSpacing(3)} ${getSpacing(6)};
  }
`;

const GradientFooter = styled.small`
  background: linear-gradient(
    to bottom,
    ${({ theme }) =>
        Color(theme.palettes.bg.primary)
          .fade(1)
          .string()}
      0%,
    ${({ theme }) => Color(theme.palettes.bg.primary).string()} 100%
  );
  bottom: 0;
  left: 0;
  right: 0;
  height: 10vh;
  position: fixed;
  @media (max-width: ${breakpoints.tablet}px) {
    height: 4vh;
  }
`;

const Main = styled.main``;

const renderThumb = theme => {
  const style = {
    backgroundColor: theme.palettes.text.secondary,
    borderRadius: "100px"
  };
  return <div style={style} />;
};

const Layout = ({ navbarLocale, metatagsLocale, children }) => (
  <ThemeProvider theme={theme}>
    <ReactBreakpoints breakpoints={breakpoints}>
      <SEO
        title={metatagsLocale.title}
        description={metatagsLocale.description}
        keywords={metatagsLocale.keywords}
      />
      <ScrollbarsWrapper>
        <Scrollbars
          universal
          renderThumbVertical={() => renderThumb(theme)}
          autoHide
        >
          <LayoutWrapper>
            <GlobalStyle />
            <Navbar locale={navbarLocale} />
            <Main>{children}</Main>
            <GradientFooter />
          </LayoutWrapper>
        </Scrollbars>
      </ScrollbarsWrapper>
    </ReactBreakpoints>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
