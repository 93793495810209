import React, { useState } from "react";
import { get } from "lodash";
import styled from "styled-components";
import scrollTo from "gatsby-plugin-smoothscroll";
import { withBreakpoints } from "react-breakpoints";
import { getSpacing, breakpoints } from "theme";
// Helpers
import { trackGAEvent } from "helpers/ga";
// Components
import { Subtitle1 } from "components/UI/Typography";
import PricingItem from "./Item";
import InfoBox from "./InfoBox";
// Icons
import IconPrototype from "media/icons/prototype.svg";
import IconMvp from "media/icons/mvp.svg";
import IconStartup from "media/icons/startup.svg";

const icons = {
  prototype: { icon: IconPrototype, animation: "circle 1s linear infinite" },
  mvp: { icon: IconMvp, animation: "launch 1s ease-in-out infinite" },
  startup: { icon: IconStartup, animation: "launch 1s ease-in-out infinite" }
};

const Title = styled(Subtitle1).attrs({ as: "h3" })`
  display: block;
  text-align: center;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${getSpacing(6)};
  grid-template-columns: repeat(3, 1fr);
  padding: ${getSpacing(6)} 10%;
  @media (max-width: ${breakpoints.tabletLandscape}px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
  }
`;

const InfoBoxStyled = styled(InfoBox)``;

const PricingWrapper = styled.div`
  padding: ${getSpacing(20)} 0;
  ${Grid} {
    margin-top: ${getSpacing(26)};
  }
  ${InfoBoxStyled} {
    margin-top: ${getSpacing(8)};
  }
`;

function Pricing({ locale, links, currentBreakpoint }) {
  const { title, buttonLabel, items } = locale;
  const { typeformUrl } = links;

  const [selectedIndex, setSelectedIndex] = useState(1);
  const selectedInfo = items[selectedIndex];

  const onSelectCardHandler = i => {
    const scrollToId =
      breakpoints[currentBreakpoint] < breakpoints.tabletLandscape
        ? "#infoBox"
        : "#pricing";
    setSelectedIndex(i);
    trackGAEvent({
      category: "Pricing Cards",
      action: "select",
      label: selectedInfo.id
    });
    scrollTo(scrollToId);
  };

  return (
    <PricingWrapper>
      <Title>{title}</Title>
      <Grid id="pricing">
        {items.map((item, i) => (
          <PricingItem
            key={item.id}
            onSelectCard={() => onSelectCardHandler(i)}
            isSelected={selectedIndex === i}
            {...get(icons, item.id, {})}
            {...item}
          />
        ))}
      </Grid>
      <InfoBoxStyled
        key={selectedIndex}
        buttonLabel={buttonLabel}
        buttonUrl={selectedInfo.url || typeformUrl}
        {...selectedInfo}
      />
    </PricingWrapper>
  );
}

export default withBreakpoints(Pricing);
